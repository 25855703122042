@use '@material/select/_index' as select;
@use '@material/textfield';
@import 'styles/variables';

#careers {
    font-family: $primaryfont;

    .legal-page-skeleton {
        border-top: unset !important;
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }


    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.card-container {
            display: flex;
            justify-content: center;
        }

        &.careers-title {
            width: 200px;
            height: 35px;

            @media (max-width: $desktop) {
                margin-left: 0;
                width: 100px;
                margin-bottom: unset;
                margin-top: 60px;
            }
        }

        &.careers-content-1 {
            height: 48px;
        }

        &.banner-2 {
            height: 262px;

            @media (max-width: $desktop) {
                height: 190px;
            }

            @media (max-width: 378px) {
                height: 185px;
            }
        }

        &.careers-s-title-1 {
            width: 200px;
            height: 35px;
        }

        &.divider-bar {
            height: 10px;
            border-top: none;
        }

        &.bar {
            display: none;
        }

        &.careers-subtitle-1 {
            width: 230px;
            height: 30px;
        }

        &.careers-content-2 {
            height: 120px;
        }

        &.careers-subtitle-2 {
            width: 250px;
            height: 30px;
        }

        &.careers-content-3 {
            height: 72px;
        }

        &.careers-subtitle-3 {
            width: 220px;
            height: 30px;
        }

        &.careers-content-4 {
            height: 72px;
        }

        &.career-body-positions-1 {
            margin-left: 470px;
            height: 64px;
            width: 300px;

            @media (max-width: 1200px) {
                margin-left: 120px;
                width: 300px;
                margin-bottom: unset;
            }

            @media (max-width: 576px) {
                margin-left: 0px;
                width: 300px;
            }

        }

        &.cx-card-1 {
            width: 294px;
            height: 142px;

            @media (max-width: 768px) {
                width: 258px;
                height: 150px;
            }
        }

        &.careers-fo-title-1 {
            position: relative;
            top: -310px;
            width: 500px;
            height: 36px;

            @media (max-width: 1200px) {
                top: 0px;
            }

            @media (max-width: 570px) {
                width: 333px;
                height: 27px;
            }
        }

        &.careers-c-content-1 {
            position: relative;
            top: -320px;
            width: 600px;
            height: 72px;

            @media (max-width: 1200px) {
                top: 0px;
            }

            @media (max-width: 570px) {
                width: 333px;
                height: 72px;
            }
        }

        &.contact-1 {
            width: 564px;
            height: 737px;
            margin-bottom: 50px;
        }
    }


    .careers-f-title p {
        font-size: $extrahugetitle;
        font-weight: 700;
        color: $black;
        margin-bottom: 50px;
        margin-top: 62px;

        @media (max-width: $widescreen) {
            margin-bottom: 32px;
        }

        @media (max-width: $phone) {
            margin-bottom: 40px;
        }
    }

    .careers-s-title {
        font-size: $extralarge;
        font-weight: 700;
        color: $black;

        p {
            margin-top: 40px;

            @media (max-width: $widescreen) {
                margin-top: 47px;
                margin-bottom: 0;
            }

            @media (max-width: $phone) {
                margin-top: 41px;
            }
        }
    }

    .line-p {
        @media (max-width: $widescreen) {
            margin-top: 12px !important;
            margin-bottom: 40px !important;
        }

        @media (max-width: $phone) {
            margin-bottom: 20px !important;
        }
    }

    .careers-fo-title {
        font-size: $extralarge;
        font-weight: 700;
        color: $black;

        & p {
            margin-top: -433px;

            @media (max-width: $widescreen) {
                margin-top: 0;
            }

            @media (max-width: $phone) {
                font-size: $large;
            }
        }
    }

    .careers-subtitle {
        font-size: $largeplus;
        font-weight: 700;
        color: $black;
    }

    .careers-c-content {

        & p {
            color: $softblack;
            margin-top: -20px !important;

            @media (max-width: $widescreen) {
                margin-top: 20px !important;
            }

            @media (max-width: $phone) {
                font-size: $regular;
            }
        }
    }

    .career-body {
        text-align: left;
        color: $softblack;

        & p {
            margin-top: 40px;

            @media (max-width: $widescreen) {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        &.culture-group p {
            margin-top: 24px;

            @media (max-width: $widescreen) {
                margin-top: 20px;
            }
        }
    }

    .career-body-positions {
        text-align: center;
        color: $softblack;

        @media (max-width: $widescreen) {
            margin-top: 36px;
        }
    }

    .cx-positions {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .career-contact {
        background: $white;
        margin-bottom: 174px;
        width: 564px;
        height: auto;
        padding: 0 50px 0 50px;

        @media (max-width: $phone) {
            width: 100%;
            max-width: 325px;
            padding-right: 40px;
            padding-left: 40px;
            margin-bottom: 2px !important;
            padding-top: 38px !important;
        }

        @media (max-width: $smallphone) {
            padding: 0 15px 0 15px;
            margin-left: -5px;
        }

        @media (max-width: $widescreen) {
            margin-bottom: 50px;
            width: 545px;
        }
    }

    .form-margin {
        margin-right: -10px;
        margin-top: 40px;

        @media (max-width: $widescreen) {
            margin-top: 0;
        }
    }

    .box-f-title {
        @media (max-width: $widescreen) {
            margin-top: 40px;
        }
    }

    .cx-card {
        height: 142px;
        width: 294px;
        border-radius: 12px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        display: grid;
        cursor: pointer;
        transition: 0.3s;
        background-color: $white;

        &:hover {
            outline: 1px solid $purple-text;
        }

        &:active {
            background: $cx-purple-gradient;
        }

        @media (max-width: $phone) {
            width: auto !important;
            height: 142px !important;
        }

        @media (max-width: $widescreen) {
            width: 258px;
            height: 158px;
        }
    }

    .cx-card:active>div>h5 {
        color: $white;
    }

    .cx-card:active>div>p {
        color: $white;
    }

    .card-title {
        font-size: $large;
        font-weight: 700;
        color: $black;
        line-height: 26px;

        @media (max-width: $widescreen) {
            margin-bottom: 9px;
        }

        @media (max-width: $phone) {
            margin-bottom: 8px;
        }
    }

    .card-text {
        color: $softblack;
    }

    .card-container {
        display: grid;
        row-gap: 25px;
        column-gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);

        @media (max-width: $widescreen) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, 1fr);
            column-gap: 25px;
            row-gap: 20px;
        }

        @media (max-width: $phone) {
            grid-template-columns: auto;
            grid-template-rows: auto;
            row-gap: 16px;

        }
    }

    .form-control {
        background: $transparent;
    }

    .form-label {
        font-size: $regular;
        font-weight: 500;
        color: $mediumblack;
        margin-top: 2px;
        margin-bottom: 11px;
    }

    .img-banner {
        max-width: 1500px;
        width: 100%;

        @media (max-width: $widescreen) {
            width: 863px;
            max-height: inherit;
            position: absolute;
            right: -39px;
            height: 190px;
        }

        @media (max-width: $phone) {
            width: 800px;
            right: -221px;
            height: 185px;
        }
    }

    .banner {
        margin-top: 30px;

        @media (max-width: $widescreen) {
            height: 185px;
            overflow: hidden;
            position: relative;
            margin-top: 38px;
        }

        @media (max-width: $phone) {
            margin-top: 41px;
        }
    }

    #cx-button button {
        padding: 0 3.16rem 0 3.16rem;
        width: 294px;
        height: 50px;
        color: $white;
        font-size: $medium;
        font-weight: 500;

        @media (max-width: $smallphone) {
            width: 100%;
            padding: 0 20px 0 20px;
        }
    }

    .attach-file-icon {
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
    }

    .message-area:hover+.attach-file-icon,
    .attach-file-icon:hover {
        opacity: 1;
        visibility: visible;
    }

    .input-size {
        max-width: 464px;
        max-height: 50px;
    }

    .cx-drop-zone-outside {
        position: relative;
        flex-direction: column;
        max-width: 464px;
        margin-bottom: 32px;

        @media (max-width: $widescreen) {
            margin-bottom: 25px;
        }

        @media (max-width: $phone) {
            margin-bottom: 14px;
        }

        .mdc-text-field__resizer {
            resize: none;
            z-index: 5;
        }

        #drop-zone {
            display: none;
            height: 90%;
            width: calc(100% - 20px);
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            align-items: center;
            justify-content: center;
            z-index: -1;

            @media only screen and (max-width: $widescreen) {
                margin-top: 0.25rem;
                border: none;
                align-content: end;
                height: 20px;
                display: contents;
            }
        }

        .wrapper-sm {
            height: 25px;
        }

        .drop-zone-lg {
            display: flex;

            &>div {
                color: $purple-text;
            }

            @media only screen and (max-width: $widescreen) {
                display: none;
            }
        }

        @media (min-width: $widescreen) {
            &:hover {
                .drop-zone-sm label {
                    display: flex;
                }
            }
        }

        @media only screen and (max-width: $widescreen) {
            .drop-zone-sm label {
                display: flex;
            }
        }
    }

    .drop-zone-sm {
        min-width: 25px;

        & label {
            display: none;
        }
    }

    .hover-drop-zone {
        background-color: rgba(49, 15, 162, 0.05);

        #drop-zone {
            border: 1px dashed $purple-text;
            display: flex;
        }
    }

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 3px;
        margin-left: 3px;
    }

    .mdc-list-item__content .file-name {
        @extend .file-name;
    }

    #wrapper-sm {
        & .cx-first-files-list {
            width: 90%;

            & .mdc-list {
                list-style-type: none;
                display: flex;

                & .mdc-list-item {
                    padding-left: 0px;
                    max-width: max-content;
                    flex: 1 1 0;

                    &.mdc-list-item--with-one-line {
                        float: left;
                        height: auto;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 4px;
                        }
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }
        }

        & .cx-dropdown-files-list {
            max-width: 15rem;
            padding: 0.25rem;

            & .mdc-list-item {
                padding-left: 0px;

                &.mdc-list-item--with-one-line {
                    height: auto;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }
        }
    }

    .mdc-select__anchor {
        max-width: 464px;
        max-height: 50px;
        background: $inputgray;
    }

    .circle-icon {
        right: 12px;
        bottom: 30px;
    }

    .mini-spinner-icon {
        right: 10px;
        bottom: 30px;
    }

    #input-email {
        &>input {
            padding-right: 20px;
        }
    }

    textarea::-webkit-scrollbar {
        width: 20px;
    }

    textarea::-webkit-scrollbar-thumb {
        background-clip: content-box;
        background-color: rgba(176, 176, 176, 0.5);
        border: 7px solid transparent;
        border-radius: 20px;

        &:hover {
            background-color: rgba(176, 176, 176, 0.6);
        }

        &:active {
            background-color: rgba(176, 176, 176, 0.7);
        }
    }

    .cx-form-control.message-area {
        @include textfield.hover-outline-color($transparent);

        &:hover {
            border: 1px solid $purplehover;
        }

        &.active {
            border: 1px solid $purple-text !important;
        }

        .mdc-text-field--focused {
            @include textfield.ink-color($black);
        }
    }

    .mdc-floating-label {
        color: $input-placeholder;
    }

    .mdc-list-item {
        background-color: $transparent;

        &:hover {
            background-color: $lilac-hover;
            border-radius: 4px;
        }

        .mdc-list-item__primary-text {
            letter-spacing: 0.68px;
            font-weight: 400;
            color: rgba(33, 37, 41, 1);
            font-size: inherit;
            font-family: inherit;
        }
    }

    .cx-select {
        @include select.outline-color(('default': $transparent, 'hover': $purplehover, 'focus': $purple-text));
        @include select.outline-shape-radius(4px);
        border-radius: 4px;
        position: relative;
        @include select.ink-color($input-placeholder);

        &:hover {
            @include select.ink-color($input-placeholder-hover);
        }

        &.mdc-select--focused {
            @include select.ink-color($black);
        }
    }

    :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before,
    :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
        opacity: 0;
    }

    .placeholder-text::before {
        content: "Select option";
    }

    .contact-f-container {
        @media (max-width: $widescreen) {
            margin-top: 20px;
        }

        @media (max-width: $phone) {
            margin-bottom: 38px;
        }
    }

    .cx-container {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
        max-width: 1236px;

        @media (max-width: $widescreen) {
            max-width: 537px;
        }

        @media (max-width: $phone) {
            max-width: 335px;
        }

        @media (max-width: $smallphone) {
            max-width: 240px;
        }
    }

    .content-separation {
        &.cx-separation {
            margin-bottom: 0;
        }
    }

    .cx-btn {
        margin-bottom: 57px;

        @media (max-width: $widescreen) {
            margin-bottom: 54px;
        }

        @media (max-width: $phone) {
            margin-bottom: 22px;
        }
    }

    #cx-button {
        .material-icons {
            margin-right: 13px !important;
        }

        .primary-style {
            background-color: $cx-purple-gradient;
        }
    }
}