@import 'styles/variables';
@import 'styles/eeta-page-style';

#code-of-ethics {

    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.title-1 {
            width: 230px;
            height: 36px;
            margin-top: 0px;

            @media (max-width: 376px) {
                width: 160px;
            }
        }

        &.text-last {
            width: 230px;
            height: 24px;
            margin-left: 530px;

            @media (max-width: 1000px) {
                margin-left: 450px;
            }

            @media (max-width: 700px) {
                margin-left: 100px;
            }

        }

        &.text-content-1 {
            height: 96px;
        }

        &.title-2 {
            width: 200px;
            height: 29px;
        }

        &.text-content-2 {
            height: 64px;
        }

        &.title-3 {
            width: 180px;
            height: 29px;
        }

        &.text-content-3 {
            height: 64px;
        }

        &.title-4 {
            width: 165px;
            height: 29px;
        }

        &.text-content-4 {
            height: 96px;
        }

        &.title-5 {
            width: 140px;
            height: 29px;
        }

        &.text-content-5 {
            height: 64px;
        }

        &.title-6 {
            width: 240px;
            height: 29px;
        }

        &.text-content-6 {
            height: 64px;
        }

        &.title-7 {
            width: 140px;
            height: 29px;
        }

        &.text-content-7 {
            height: 96px;
        }

        &.title-8 {
            width: 260px;
            height: 29px;
        }

        &.text-content-8 {
            height: 64px;
        }
    }
}