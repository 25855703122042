@import 'styles/variables';
@import 'styles/eeta-page-style';

#complaints-policy {
    
    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;
    

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.title-1 {
            width: 230px;
            height: 36px;
        }

        &.text-1 {
            margin-left: 530px;
            width: 230px;
            height: 24px;

            @media (max-width: 1000px) {
                margin-left: 450px;
            }

            @media (max-width: 700px) {
                margin-left: 100px;
            }
        }

        &.title-2 {
            width: 210px;
            height: 29px;
        }

        &.text-content-1 {
            height: 96px;
        }

        &.title-3 {
            width: 270px;
            height: 29px;
        }

        &.text-content-2 {
            height: 96px;
        }

        &.title-4 {
            width: 270px;
            height: 29px;
        }

        &.text-content-3 {
            height: 96px;
        }

        &.title-5 {
            width: 270px;
            height: 29px;
        }

        &.text-content-4 {
            height: 64px;
        }
    }
}